import { Dialog, DialogActions, DialogContent, DialogTitle, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";
import ForwardIcon from '@mui/icons-material/Forward';
import { Table } from "reactstrap";
import { porttcfolio } from "./data/CryptoData";
import FieldEditorV2 from "./v2/editor/FieldEditorV2";
import { convertToDisplayCurrency, getDisplayCurrency, switchCurrency } from "./v2/util/PortfolioUtil";
import { DisplayModeEnum } from "./v2/data/CryptoDataTypeV2";

const CryptoHeaderSummaryDialog = forwardRef((props: any, ref) => {

    const [open, setOpen] = useState(false);
    const [updated, setUpdated] = useState(false);

    const updatePorttcfolioInvestment = () => { }

    useImperativeHandle(ref, () => ({
        handleOpen() {
            setOpen(true);
        },
        handleClose() {
            setOpen(false);
        }
    }));

    const handleApply = () => {
        setOpen(false);
    };

    const switchCurrency2 = () =>{
        switchCurrency();
        setUpdated(!updated);
    }

    let toto = 0;

    let cryptosSummary: any = [];
    if (porttcfolio.transient.cryptoSummary) {
        for (let i = 0; i < porttcfolio.transient.cryptoSummary.length; i++) {
            let summary = porttcfolio.transient.cryptoSummary[i];
            cryptosSummary.push(<tr key={"cryptosummaryTR"+Math.random}><td>{summary.symbol}</td><td>{convertToDisplayCurrency(summary.totalLeft,0)}</td><td>{convertToDisplayCurrency(summary.sold,0)}</td><td>{convertToDisplayCurrency(summary.reinvested,0)}</td></tr>);
            toto += summary.totalLeft;
        }
    }


    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
            <DialogTitle>Résumé financier</DialogTitle>
            <DialogContent>
                <TableContainer component={Paper}>
                    <form id="headerSummaryForm">
                        <Table className="crypto-summary-dialog">
                            <tr>
                                <td width="50%">
                                    <Table width="100%">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                <a onClick={switchCurrency2} className="ttcButton">
                                                {porttcfolio.transient.displayInUserCurrency?"USDT":porttcfolio.currency}
                                                </a>                                                    
                                                </TableCell>
                                                <TableCell>Montant <br/>{getDisplayCurrency()}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <tr><th>Capital BRUT:</th><td>{convertToDisplayCurrency(porttcfolio.transient.totalBeforeTax,0)}</td></tr>
                                            <tr><th>Impots:</th><td>{convertToDisplayCurrency(porttcfolio.transient.totalTax,0)}</td></tr>
                                            <tr><th>Capital NET:</th><td>{convertToDisplayCurrency(porttcfolio.transient.totalAfterTax,0)}</td></tr>
                                            <tr><th>&nbsp;</th></tr>
                                            <tr><th>Montant déposé USDT:<FieldEditorV2
                                                className="fixedWidth"
                                                tooltip="Montant déposé" label=""
                                                object={porttcfolio} objectKey="investment"
                                                onChange={updatePorttcfolioInvestment}
                                                displayMode={DisplayModeEnum.EDIT}
                                                type="number" key={"key10" + Math.random()} /></th><td>
                                                    {convertToDisplayCurrency(porttcfolio.investment,0)}
                                                    </td></tr>
                                            <tr><th>Gains:</th><td>{convertToDisplayCurrency(porttcfolio.transient.totalGains,0)}</td></tr>
                                            <tr><th>&nbsp;</th></tr>
                                            <tr><th>Montant des ventes:</th><td>{convertToDisplayCurrency(porttcfolio.transient.totalSold,0)}</td></tr>
                                            <tr><th>Montant réinvesti:</th><td>{convertToDisplayCurrency(porttcfolio.transient.totalReInvested,0)}</td></tr>
                                            <tr><th>Montant ventes après réinvestissement:</th><td>{convertToDisplayCurrency(porttcfolio.transient.totalSoldMinusReinvested,0)}</td></tr>
                                        </TableBody>
                                    </Table>
                                </td>
                                <td width="50%">
                                    <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                                        <Table width="100%">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={"crypto"}>Crypto</TableCell>
                                                    <TableCell>Capital actuel <br/>restant {getDisplayCurrency()}</TableCell>
                                                    <TableCell>Vente<br/>{getDisplayCurrency()}</TableCell>
                                                    <TableCell>Réinvestissement<br/>{getDisplayCurrency()}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {cryptosSummary}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </td>
                            </tr>
                        </Table>


                    </form>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <button onClick={handleApply} className="ttcButton">
                    Fermer
                </button>
            </DialogActions>
        </Dialog>
    );
});

export default CryptoHeaderSummaryDialog;