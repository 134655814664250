import React, { useState, useEffect, useRef } from "react";
import "../Crypto.css";
import logo from '../../cryptoverview2.png';
import CryptoHeader from "../CryptoHeader";
import CryptoContent from "../CryptoContent";
import { CryptosV2, DisplayModeEnum } from "./data/CryptoDataTypeV2";
import { initDummyPortfolio, porttcfolio, reloadPorttcfolioFromJsonV2 } from "../data/CryptoData";
import { filterPorttcfolioV2, initPorttcfolioV2, sortPorttcfolioV2 } from "../data/CryptoProcessData";
import { initAdminExchanges } from "../admin/AdminExchanges";
import axios from "axios";

export const CryptoPageV2 = () => {

  const [ttcCryptosV2, setTtcCryptosV2] = useState<CryptosV2>({ items: [] });
  const [search, setSearch] = useState("");
  const [processing, setProcessing] = useState(true);
  let [viewMode, setViewMode] = React.useState(true);

  let [updated, setUpdated] = useState(true);
  const forceUpdate = () => {
    setUpdated(!updated);
  }

  const delay = (ms: any) => new Promise(res => setTimeout(res, ms));

  const waitForAllLoaded = async (setTtcCryptos: any, filterData: any, forceUpdate: any) => {

    while ((!porttcfolio.transient) || (!(porttcfolio.transient as any).allLoaded)) {
      await delay(1000);
    }
    console.log(processing+" - "+ porttcfolio.transient.allLoaded);

    setTtcCryptos({ items: filterData([]) });
    if (forceUpdate) {
      forceUpdate();
    }
  }


  const fullRefresh = () => {
    setProcessing(true);
    initPorttcfolioV2(headerRef);
    sortPorttcfolioV2();
    filterPorttcfolioV2('');
    waitForAllLoaded(setTtcCryptosV2, filterData, forceUpdate);
  }

  const partialRefresh = (needToSort: boolean) => {
    setProcessing(true);
    if (needToSort) {
      sortPorttcfolioV2();
    }
    setTtcCryptosV2({ items: filterData('') });
  }

  const filterData = (src: any) => {
    setProcessing(false);
    return filterPorttcfolioV2(src);
  }


  //init with data from cache
  const headerRef = useRef(null);
  let data = localStorage.getItem("cryptool");

  useEffect(() => {
    console.log("useeffect");
    


    setProcessing(true);
    if (porttcfolio.cryptosV2.length == 0) {
      if (data != null) {
        reloadPorttcfolioFromJsonV2(data, headerRef);
      } else {
        initDummyPortfolio();
      }
      porttcfolio.transient.allLoaded = false;
      initAdminExchanges();
      initPorttcfolioV2(headerRef);

      waitForAllLoaded(setTtcCryptosV2, filterData, null);
    }


  }, []);

  type MaFonction = (param: any) => void;

  const handleSearch: MaFonction = (e: any) => {
    setSearch(e.target.value);
  };

  const filteredttcCryptos = ttcCryptosV2.items.filter((ttcCrypto: any) =>
    (ttcCrypto.name && ttcCrypto.name.toLowerCase().includes(search.toLowerCase()))
    || (ttcCrypto.symbol && ttcCrypto.symbol.toLowerCase().includes(search.toLowerCase()))
  );


  let filtered: CryptosV2 = { items: [] }
  filtered.items = filteredttcCryptos;

  /*
  const toggleViewMode = () => {
    let vm = !viewMode;
    setViewMode(!viewMode);
    for(let cryptoV2 of porttcfolio.cryptosV2){
      if(vm){
        cryptoV2.displayMode=DisplayModeEnum.VIEW;
      }else{
        cryptoV2.displayMode=DisplayModeEnum.EDIT;

      }
    }
    if (vm) {
      fullRefresh();
      setSearch('');
    } else {
      partialRefresh(true);
    }
  }*/


  const buyPopupRef = useRef(null);

  return (
    <div className="App-header viewMode" id="App-header">
      {(!processing) && (porttcfolio.transient.allLoaded) &&
        <div className="crypto-table-root">
          <CryptoHeader cryptoRows={filtered} viewMode={viewMode}
            partialRefresh={partialRefresh} fullRefresh={fullRefresh}
            ref={headerRef}
            handleChange={handleSearch} />
          <CryptoContent cryptoRows={filtered} viewMode={viewMode}
            buyPopupRef={buyPopupRef}
            partialRefresh={partialRefresh} fullRefresh={fullRefresh} />
        </div>
      }
      {processing && <div id="processing"><div className="crypto-logo"><img src={logo} className="App-logo" alt="logo" /></div></div>}
      {processing && <div id="processing2"><div className="crypto-logo-text"><br /><br /><br /><br />Traitement...</div></div>}
    </div>
  );
}

export default CryptoPageV2;
