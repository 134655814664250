import { useState } from "react";
import { Tooltip } from "@mui/material";
import { updatePortfolioInBrowserCache } from "../../data/CryptoCache";
import { getCoingeckoIdSuggestions } from "../../data/CryptoGeeki";
import { porttcfolio } from "../../data/CryptoData";
import { DisplayModeEnum } from "../data/CryptoDataTypeV2";

const CoingeckoCodeEditor = (val: any) => {


    let [stateData,setStateData]=useState(
        {showList:false,inputValue:val.cryptoV2.coingeckoId
            ,coingeckoIdSuggestions:[]
        ,value:val});
        console.log("statedata");
        console.log(stateData);

    const toggleList = () => {
        if (stateData.showList) {
            setStateData((prev) => ({
                ...prev,         
                showList: !stateData.showList,   
              }));
        } else {
            getCoingeckoIdSuggestions(val.cryptoV2.symbol).then((res: any) => {
                setStateData((prev) => ({
                    ...prev,         
                    coingeckoIdSuggestions: res.data.result,   
                    showList: !stateData.showList
                  }));
            });
        }
    };

    const handleSelectItem = (item: any) => {
        val.cryptoV2.coingeckoId = item.code;
        for(let cv2 of porttcfolio.cryptosV2){
            if(cv2.ttcId === val.cryptoV2.ttcId){
                cv2.coingeckoId=item.code;
                break;
            }
        }
    
        updatePortfolioInBrowserCache();
        console.log("statedata1");
        console.log(stateData);
        console.log("porttfcolio cryptos");
        console.log(porttcfolio.cryptosV2);
    
        setStateData((prev) => ({
            ...prev,
            inputValue: item.code,
            showList:false
        }));
        console.log("statedata2");
        console.log(stateData);
    };

    const blurMe = (newValue: any) => {
        val.cryptoV2.coingeckoId = newValue;
        updatePortfolioInBrowserCache();
    }

    let myValue=stateData.inputValue;
    if (val.displayMode === DisplayModeEnum.VIEW) {
        return <Tooltip title={val.tooltip}><span className={"fieldEditor viewMode crypto-row-symbol fixedWidth"}>{val.cryptoV2.coingeckoId}</span></Tooltip>
    } else {
        return (<Tooltip title={val.tooltip}>
            <span className={"fieldEditor editMode crypto-row-symbol fixedWidth"}><input type="text" value={myValue}
                onBlur={e => blurMe(e.target.value)} className="fieldEditor text" />
                <button onClick={toggleList} className="ttcButton"> {stateData.showList ? 'Masquer la liste' : 'Afficher la liste'}</button>
                {stateData.showList && (
                    <ul>
                        {stateData.coingeckoIdSuggestions && stateData.coingeckoIdSuggestions.map((item: any) => (
                            <li
                                key={item.code}
                                onClick={() => handleSelectItem(item)}
                                style={{ cursor: 'pointer', margin: '5px 0' }}
                            >
                                {item.code}
                            </li>
                        ))}
                    </ul>
                )}</span></Tooltip>);
    }
}

export default CoingeckoCodeEditor;
