import { useState } from "react";
import "../../CryptoTable.css";
import { IconButton, TableCell, Tooltip } from "@mui/material";
import RemoveIcon from '@mui/icons-material/RemoveCircle';
import SellIcon from '@mui/icons-material/Sell';
import { toFixed } from "../../data/CryptoData";
import TargetEditorV2 from "../editor/TargetEditorV2";
import StatusEditorV2 from "../editor/StatusEditorV2";
import { getTargetColor } from "../../data/CryptoConfig";
import { DisplayModeEnum, StatusEnum } from "../data/CryptoDataTypeV2";


const CryptoRowTargetV2 = (props: any) => {

    let [updated, setUpdated] = useState(false);

    const toggleUpdated = () => {
        setUpdated(!updated);
        props.partialRefresh(false);
    }
    const partialRefresh = () => {
        props.partialRefresh(false);
    }

    const onRemove = () => {
        props.onRemoveTarget(props.targetPos);
    }

    let cryptoV2 = props.cryptoV2;
    let targetPos = props.targetPos;
    let cryptoExchangeV2 = props.cryptoExchangeV2;

    if (!cryptoExchangeV2.targets) {
        return (<TableCell className="rowTarget" key={"key01" + Math.random() + "-" + Math.random()} align="right"></TableCell>);
    } else if ((!cryptoExchangeV2.targets[targetPos])) {
        return (<TableCell className="rowTarget" key={"key01" + Math.random() + "-" + Math.random()} align="right"></TableCell>);
    } else if (cryptoExchangeV2.targets.length > targetPos) {
        let numPrice = cryptoExchangeV2.targets[targetPos].price;
        let price = (numPrice == null || numPrice === undefined ? null : numPrice.toFixed(2));
        let numBag = cryptoExchangeV2.targets[targetPos].bag;
        let bag = (numBag == null || numBag === undefined ? null : numBag.toFixed(2));

        let target1Perc = cryptoExchangeV2.targets[targetPos].perc;
        let cssTarget1Perc = toFixed(100 - target1Perc, 0);
        let gradiant = getTargetColor((target1Perc != null ? target1Perc : -1), "0.1");

        let background = "lightblue";
        let isSold = (cryptoExchangeV2.targets[targetPos].status === StatusEnum.Sold);
        if (!isSold) {
            background = "linear-gradient( rgba(0,0,0,0) 0%,rgba(0,0,0,0) " + cssTarget1Perc + "%, " + gradiant + " " + cssTarget1Perc + "%, " + gradiant + " 100%) ";
        }

        return (<TableCell align="right" className={"rowTarget removeTarget" + (isSold ? " sold" : "")}
            style={{ background: background }}
            colSpan={cryptoExchangeV2.targets[targetPos].bagPerc}>

            <TargetEditorV2
                cryptoV2={cryptoV2}
                cryptoExchangeV2={cryptoExchangeV2}
                tooltip="Cours de vente en USDT"
                className="fixedWidth"
                targetPos={targetPos} prop="rate"
                partialRefresh={partialRefresh} fullRefresh={props.fullRefresh}
                onChange={toggleUpdated} type="currency" key={"key12" + Math.random() + "-" + Math.random()} />
            <div className="fieldEditor-container"><TargetEditorV2
                cryptoV2={cryptoV2}
                cryptoExchangeV2={cryptoExchangeV2}
                tooltip="Pourcentage du bag"
                className="fixedWidth"
                targetPos={targetPos} prop="bagPerc"
                partialRefresh={partialRefresh} fullRefresh={props.fullRefresh}
                onChange={toggleUpdated} type="percentage" key={"key131" + Math.random() + "-" + Math.random()} /></div>
            {cryptoV2.displayMode === DisplayModeEnum.VIEW ? <div className="fixedWidth alignRight"><Tooltip title="Montant de l'objectif"><div>$ {price}</div></Tooltip></div> : <br />}

            {cryptoV2.displayMode === DisplayModeEnum.VIEW && (cryptoExchangeV2.targets[targetPos].status === StatusEnum.Order)
                && (<div className="fixedWidth">{cryptoExchangeV2.targets[targetPos].status === StatusEnum.Order && <span className="targetOrdered">
                    <Tooltip title="Ordre de vente"><SellIcon />
                    </Tooltip>
                </span>}

                    {cryptoV2.displayMode === DisplayModeEnum.VIEW ? (<Tooltip title="Bag à vendre"><span>bag: {bag}</span></Tooltip>) : <br />}</div>)}
            {cryptoV2.displayMode === DisplayModeEnum.VIEW && (cryptoExchangeV2.targets[targetPos].status !== StatusEnum.Order)
                && <div className="fixedWidth alignRight"><Tooltip title="Bag à vendre"><span>bag: {bag}</span></Tooltip></div>}


            {cryptoV2.displayMode === DisplayModeEnum.EDIT && <Tooltip title="Statut de l'ordre"><div>
                <StatusEditorV2
                    targetPos={targetPos}
                    cryptoV2={cryptoV2}
                    cryptoExchangeV2={cryptoExchangeV2} /></div></Tooltip>}

            {cryptoExchangeV2.targets[targetPos].status === StatusEnum.Sold && <div>&nbsp;</div>}
            {cryptoExchangeV2.targets[targetPos].status === StatusEnum.Sold && <div className="fieldEditor-container"><div>&nbsp;</div>
                <TargetEditorV2
                    cryptoV2={cryptoV2}
                    cryptoExchangeV2={cryptoExchangeV2}
                    tooltip="Montant réinvesti. Le montant ne sera pas utilisé pour calculer les gains car il est réinvesti."
                    className="fixedWidth"
                    targetPos={targetPos} val={cryptoExchangeV2} prop="reinvestedAmount"
                    partialRefresh={partialRefresh} fullRefresh={props.fullRefresh}
                    onChange={toggleUpdated} type="currency" key={"key1321" + Math.random() + "-" + Math.random()} /></div>

            }

            {cryptoExchangeV2.targets.length - 1 === targetPos &&
                <Tooltip title="Supprimer objectif"><IconButton onClick={onRemove} className="removeTarget">
                    <RemoveIcon />
                </IconButton></Tooltip>
            }
            {(!isSold) && <Tooltip title="% Objectif réalisé"><span className="percTarget">{toFixed(target1Perc, 2)}%</span></Tooltip>}
            {isSold && <span className="percTarget">Vendu</span>}
        </TableCell>
        );
    } else {
        return (<TableCell key={"key01" + Math.random() + "-" + Math.random()} align="right"></TableCell>);
    }

}

export default CryptoRowTargetV2;