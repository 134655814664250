import { porttcfolio } from "../../data/CryptoData";
import { CryptoExchangeV2, CurrencyEnum } from "../data/CryptoDataTypeV2";

export const usedBagPercV2 = (cryptoExchangeV2: CryptoExchangeV2) => {
    let total: number = 0;
    if (cryptoExchangeV2) {
      let targets = cryptoExchangeV2.targets;
      if (targets) {
        for (let i = 0; i < targets.length; i++) {
          let target = targets[i];
          let bagPerc: number = (target.bagPerc === undefined || target.bagPerc == null) ? 0 : Number(target.bagPerc);
          total = total + bagPerc;
        }
      }
    }
    return total;
  }

  export const isStableCoin = (symbol: string) => {
    if (symbol == null || symbol === undefined) {
      return false;
    } else{
      return (symbol.toUpperCase() === 'USDT' || symbol.toUpperCase() === 'USDC');
    }
  }
  
  export const generateTtcId = () => {
    return '' + (new Date()).getTime() + '-' + Math.random();
  }

  export const switchCurrency = () => {
    if(porttcfolio.transient.displayInUserCurrency){
      porttcfolio.transient.displayInUserCurrency=false
    }else{
      porttcfolio.transient.displayInUserCurrency=true;
    }
  }

  export const getDisplayCurrency = () => {
    return porttcfolio.transient.displayInUserCurrency?porttcfolio.currency:"USDT";
  }

  export const convertToDisplayCurrency = (srcAmount:any,toFixed:number) => {
    if(srcAmount==null || srcAmount===undefined || (isNaN(srcAmount))){
      return 0;
    }else if((!porttcfolio.transient.displayInUserCurrency)||porttcfolio.currency===CurrencyEnum.USD){
     if(isNaN(srcAmount)){
      return srcAmount;
     }else{
      return Number(srcAmount).toFixed(toFixed);
     }
    }else if(porttcfolio.transient.rateEurUsd!== undefined && porttcfolio.transient.rateEurUserCurrency!==undefined){
      //convert USDT->EUR
      let eurAmount=srcAmount/porttcfolio.transient.rateEurUsd;
      //then convert EUR->currency
      let currencyAmount=eurAmount * porttcfolio.transient.rateEurUserCurrency;
      return currencyAmount.toFixed(toFixed);
    }else{
      return 0;
    }
  }