import { useState, forwardRef, useImperativeHandle } from 'react';
import "../Crypto.css";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemText
} from '@mui/material';
import AdminExchanges from './AdminExchanges';
import AdminAccount from './AdminAccount';

// Utilisation de forwardRef pour exposer des méthodes via ref
const Admin = forwardRef((props, ref) => {

    const [open, setOpen] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState<string>("Exchanges");

    // Exposer la fonction handleOpen via ref
    useImperativeHandle(ref, () => ({
        handleOpen() {
            setOpen(true);
        },
        handleClose() {
            setOpen(false);
        }
    }));

    const renderContent = () => {
        switch (selectedMenu) {
          case "Exchanges":
            return <AdminExchanges />;
          case "Account":
            return <AdminAccount />;
          default:
            return <p>Sélectionnez une option dans le menu.</p>;
        }
      };

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
            <DialogTitle>Administration</DialogTitle>
            <DialogContent>
            <Box display="flex">
          {/* Menu vertical */}
          <Box
            sx={{
              width: "30%",
              borderRight: "1px solid #ddd",
              padding: "8px 0",
            }}
          >
            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={() => setSelectedMenu("Account")}>
                  <ListItemText primary="Compte" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => setSelectedMenu("Exchanges")}>
                  <ListItemText primary="Exchanges" />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>

          {/* Contenu correspondant */}
          <Box sx={{ flex: 1, padding: 2 }}>{renderContent()}</Box>
        </Box>
            </DialogContent>
            <DialogActions>
                <button onClick={() => setOpen(false)} className="ttcButton">
                    Fermer
                </button>
            </DialogActions>
        </Dialog>
    );
});

export default Admin;
