import { MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import { porttcfolio } from "../data/CryptoData";
import { updatePortfolioInBrowserCache } from "../data/CryptoCache";
import { CurrencyEnum } from "../v2/data/CryptoDataTypeV2";

interface IProps {
}

const AdminAccount: React.FC<IProps> = () => {

    const handleChange = (newValue: any) => {
        porttcfolio.currency = newValue;
        updatePortfolioInBrowserCache();
    };

    return (<TableContainer component={Paper}>
        <form id="adminForm">
            <Table>
                <TableHead>
                </TableHead>
                <TableBody>
                    <TableRow >
                        <TableCell>
                            Devise
                        </TableCell>
                        <TableCell>
                            <Select
                                className="statusEditor2"
                                value={porttcfolio.currency ? porttcfolio.currency : CurrencyEnum.EUR}
                                onChange={e => handleChange(e.target.value)}    >
                                <MenuItem value={CurrencyEnum.EUR}>EUR</MenuItem>
                                <MenuItem value={CurrencyEnum.USD}>USD</MenuItem>
                                <MenuItem value={CurrencyEnum.CAD}>CAD</MenuItem>
                                <MenuItem value={CurrencyEnum.CHF}>CHF</MenuItem>
                            </Select>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </form>
    </TableContainer>);
}

export default AdminAccount;