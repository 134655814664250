import axios from "axios";
import { calculPrice, porttcfolio } from "./CryptoData";
import { updatePortfolioInBrowserCache } from "./CryptoCache";
import { CryptoV2 } from "../v2/data/CryptoDataTypeV2";


export async function getCoingeckoIdSuggestions(symbol: string) {
    const res = await axios.get("https://www.geeki.fr/api/cryptoSuggest/" + symbol);
    return res;
}

export async function getCurrencyRate(name: string) {
    const res = await axios.get("https://www.geeki.fr/api/cryptos/currencies/" + name);
    return res;
}

export async function getFearAndGreed(){    
    const res = axios.get("https://www.geeki.fr/api/cryptos/fag");
    return res;
}

export const getGeekiPricesV2 = (symbols: any[]) => {

    if (symbols != null && symbols !== undefined) {
        porttcfolio.transient.allLoaded = false;
        axios
            .post(
                "https://www.geeki.fr/api/cryptos2/", { cryptos: symbols }, {
                withCredentials: false,
                headers: {
                    'Accepts': 'application/json',
                    'Access-Control-Allow-Credentials': true,
                    "Access-Control-Allow-Origin": "*",
                    'Access-Control-Allow-Headers': 'Origin'
                }
            })
            .then((res) => {
                let resData = res.data;
                for (let i = 0; i < resData.length; i++) {

                    let data = resData[i];
                 //   console.log(data);
                    let cryptosV2 = porttcfolio.cryptosV2;
                    let cryptoV2;
                    for (let j = 0; j < cryptosV2.length; j++) {
                        cryptoV2 = cryptosV2[j];
                        cryptoV2.symbol = cryptoV2.symbol.split("|")[0];
                        if (cryptoV2.symbol.toUpperCase() === data.symbol.toUpperCase()
                            &&(cryptoV2.coingeckoId  == null || cryptoV2.coingeckoId === undefined 
                        ||cryptoV2.coingeckoId == data.coingeckoId)
                            && (
                                (
                                    (data.coingeckoId != null)
                                    || (
                                        cryptoV2.name == null || cryptoV2.name === undefined
                                        || cryptoV2.name!.toUpperCase() === data.name.toUpperCase())))
                                        ) {


                            cryptoV2.symbol = data.symbol.toUpperCase();
                            if (data.name != null && data.name !== undefined) {
                                cryptoV2.name = data.name.toUpperCase();
                            }
                            if (data.coingeckoId != null && data.coingeckoId !== undefined) {
                                cryptoV2.coingeckoId = data.coingeckoId;
                            }
                            cryptoV2.unitPrice = data.price;

                            cryptoV2.price_change_percentage_24h = isNaN(data.price_change_percentage_24h) ? Number(data.price_change_percentage_24h) : data.price_change_percentage_24h;
                            cryptoV2.market_cap = data.market_cap;
                            cryptoV2.market_cap_rank = data.market_cap_rank;
                            cryptoV2.ath = data.ath;
                            if (data.image != null && data.image !== undefined) {
                                cryptoV2.image = data.image;
                            }
                            cryptoV2.history = data.history;
                        }
                    }
                }
                let cryptosV2 = porttcfolio.cryptosV2;
                for (let j = 0; j < cryptosV2.length; j++) {
                    calculExchangesPrices(cryptosV2[j]);
                }
                porttcfolio.transient.allLoaded = true;
                updatePortfolioInBrowserCache();
            })
            .catch((error) => {
                porttcfolio.transient.allLoaded = true;
                console.log(error);
                updatePortfolioInBrowserCache();
            })
    }
}

export const calculExchangesPrices = (cryptoV2: CryptoV2) => {
    cryptoV2.cryptoBagPrice = 0;
    for (let k = 0; k < cryptoV2.exchanges.length; k++) {
        let exc = cryptoV2.exchanges[k];
        let cryptoV2Price = (cryptoV2.unitPrice === undefined ? 0 : cryptoV2.unitPrice);
        exc.exchangeBagPrice = calculPrice(exc.bag, cryptoV2Price);
        cryptoV2.cryptoBagPrice += exc.exchangeBagPrice;
    }
}