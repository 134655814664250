import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import { CryptoExchangeV2, CryptoV2, DisplayModeEnum } from "../data/CryptoDataTypeV2";
import CryptoRowExchangeV2 from "./CryptoRowExchangeV2";
import { toFixed } from "../../data/CryptoData";
import { AddCircle } from "@mui/icons-material";
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import { useState } from "react";
import { updatePortfolioInBrowserCache } from "../../data/CryptoCache";
import FieldEditorV2 from "../editor/FieldEditorV2";
import CoingeckoCodeEditor from "../editor/CoingeckoCodeEditor";
import transImg from '../../../trans.gif';
import CryptoRowCryptoV2TargetsSummary from "./CryptoRowCryptoV2TargetsSummary";
import { generateTtcId } from "../util/PortfolioUtil";

const CryptoRowCryptoV2 = (props: any) => {



    let cryptoV2: CryptoV2 = props.cryptoV2;

    const [state, setState] = useState({ expanded: (props.expanded) });
    const [updated, setUpdated] = useState(false);

    const partialRefresh = () => {
        props.partialRefresh(true);
    }

    const doCollapse = () => {
        setState({ ...state, expanded: false });
    }

    const doExpand = () => {
        setState({ ...state, expanded: true });
    }

    const doRefresh = () => {
        setUpdated(!updated);
    }

    const doNothing = () => {

    }

    const onSwitchEdit = () => {
        cryptoV2.displayMode = DisplayModeEnum.EDIT;
        doRefresh();
    }

    const onSwitchView = () => {
        cryptoV2.displayMode = DisplayModeEnum.VIEW;
        doRefresh();
    }


    const onExchangeAdd = () => {
        let newCryptoExchangeV2: CryptoExchangeV2 = {
            name: 'NOM EXCHANGE', ttcId: generateTtcId(), bag: 0,
            exchangeBagPrice: 0, automaticUpdate: true,
            updatedFromExchange: false, targets: []
        }
        cryptoV2.exchanges.unshift(newCryptoExchangeV2);
        updatePortfolioInBrowserCache();
        doExpand();
    }

    const onRemoveTarget = (ttcId: string, targetPos: number) => {
        let trouve = false;
        for (let j = 0; j < cryptoV2.exchanges.length; j++) {
            let exc = cryptoV2.exchanges[j];
            if (exc.ttcId === ttcId) {
                exc.targets.splice(targetPos, 1);
                trouve = true;
                break;
            }
        }
        if (trouve) {
            updatePortfolioInBrowserCache();
            doRefresh();
        }
    }

    let result = [];
    if (state.expanded) {
        result.push(    <TableRow className={" filterable filterable-"+cryptoV2.symbol}>
            <TableCell align="center" padding={'normal'} className="percLeft">
            </TableCell>
            <TableCell
              key={"symbol"}
              align="center"
              colSpan={2}
              padding={'normal'}
              className="head percLeft"
            >
              Exchange
            </TableCell>
            <TableCell
              colSpan={2}
              key={"target1Perc"}
              className="crypto-row-targets-v2 head percLeft"
              align="center"
              padding={'normal'}
            >
                Objectifs
            </TableCell>
          </TableRow>);
        for (let i = 0; i < cryptoV2.exchanges.length; i++) {
            let cryptoExchangeV2 = cryptoV2.exchanges[i];
            result.push(<CryptoRowExchangeV2 pos={i} cryptoExchangeV2={cryptoExchangeV2} cryptoV2={cryptoV2}
                groupedRowsCount={cryptoV2.exchanges.length}
                buyPopupRef={props.buyPopupRef}
                partialRefresh={props.partialRefresh} fullRefresh={props.fullRefresh}

                onAddTarget={doRefresh}
                onRemoveTarget={onRemoveTarget}
                onRemove={props.onRemove} key={"cryptorow" + Math.random() + "-" + Math.random()} />);
        }
    }

    let history: number[] = [];
    let history2 = [];
    let strHistory = cryptoV2.history ? cryptoV2.history.split('_') : [];
    for (let i = 0; i < strHistory.length; i++) {
        history.push(Number(strHistory[i]));
        history2.push({ value: Number(strHistory[i]) });
    }
    /*
    let historyColor = "green";
    if (history.length > 1) {
        if (history[0] > history[history.length - 1]) {
            historyColor = "red";
        }
    }

                {history && (history.length > 0) &&
                    <Tooltip title="Evolution sur 14 jours">
                        <span>
                            <AreaChart data={history2} width={100} height={50}>
                                <Area
                                    type="monotone"
                                    dataKey="value"
                                    stroke={historyColor}
                                    fill={historyColor}
                                    fillOpacity={0.3}
                                />
                            </AreaChart>
                        </span>
                    </Tooltip>

                } */

//filterable-"+cryptoExchangeV2.name.replaceAll(" ","")
let exchangesFilters="";


    return (<>
        <TableRow
            key={"key07" + Math.random() + "-" + Math.random()}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            className={"crypto-row filterable filterable-"+cryptoV2.symbol}
            onClick={cryptoV2.displayMode == DisplayModeEnum.VIEW ? (state.expanded ? doCollapse : doExpand) : doNothing}
        >
            <TableCell align="center" padding={'normal'} className="addRow percLeft">
                <Tooltip title="Ajouter ligne"><IconButton onClick={onExchangeAdd}>
                    <AddCircle />
                </IconButton></Tooltip>
                <br />
                {cryptoV2.displayMode == DisplayModeEnum.VIEW &&
                    <Tooltip title="Ajouter ligne"><IconButton onClick={onSwitchEdit}>
                        <EditIcon />
                    </IconButton></Tooltip>}
                {cryptoV2.displayMode == DisplayModeEnum.EDIT &&
                    <Tooltip title="Ajouter ligne"><IconButton onClick={onSwitchView}>
                        <EditOffIcon />
                    </IconButton></Tooltip>}
            </TableCell>
            <TableCell key={"key13" + Math.random() + "-" + Math.random()}
                align="left">
                {cryptoV2.image && <span className="crypto-cell-V2-img"><img src={cryptoV2.image} alt="crypto" /></span>}
                {(!cryptoV2.image) && <span className="crypto-cell-V2-img"><img src={transImg} alt="" /></span>}
                <br />
                {state.expanded && <Tooltip title="Minimiser"><IconButton onClick={doCollapse}>
                    <UnfoldLessIcon />
                </IconButton></Tooltip>}
                {(!state.expanded) && <Tooltip title="Maximiser"><IconButton onClick={doExpand}>
                    <UnfoldMoreIcon />
                </IconButton></Tooltip>}
            </TableCell>
            <TableCell colSpan={cryptoV2.displayMode == DisplayModeEnum.VIEW ? 1 : 3} key={"key13" + Math.random() + "-" + Math.random()}
                align="left" className={cryptoV2.displayMode == DisplayModeEnum.VIEW ? "crypto-cell-V2 percLeft" : "percLeft"}>
                <span className="crypto-cell-V2-data">
                    {cryptoV2.coingeckoId && <a className="coingeckoId crypto-row-symbol"
                        href={"https://www.coingecko.com/en/coins/" + cryptoV2.coingeckoId} target="_new">{cryptoV2.symbol} - {cryptoV2.name} <Tooltip title="Rang(Market Cap)">
                            <span>{(cryptoV2.market_cap_rank == null || cryptoV2.market_cap_rank === 0) ? '' : '(' + cryptoV2.market_cap_rank + ')'}</span></Tooltip></a>}
                    {(!cryptoV2.coingeckoId) && <span><FieldEditorV2
                        tooltip="Code de la cryptomonnaie"
                        className="crypto-row-symbol fixedWidth"
                        object={cryptoV2} objectKey="symbol"
                        rows={props.rows}
                        displayMode={cryptoV2.displayMode}
                        onChange={partialRefresh} type="text" key={"key111" + Math.random() + "-" + Math.random()} />
                    </span>}
                    {cryptoV2.displayMode == DisplayModeEnum.EDIT && <span><FieldEditorV2
                        tooltip="Nom de la cryptomonnaie"
                        className="crypto-row-symbol fixedWidth"
                        object={cryptoV2} objectKey="name"
                        rows={props.rows}
                        displayMode={cryptoV2.displayMode}
                        onChange={partialRefresh} type="text"
                        key={"key112" + Math.random() + "-" + Math.random()} />

                    </span>}
                    {cryptoV2.displayMode == DisplayModeEnum.VIEW && <br />}
                    {cryptoV2.displayMode == DisplayModeEnum.VIEW && <span>&nbsp;</span>}
                    {cryptoV2.displayMode == DisplayModeEnum.EDIT && <span><CoingeckoCodeEditor
                        tooltip="Code Coingecko"
                        cryptoV2={cryptoV2}
                        rows={props.rows}
                        displayMode={cryptoV2.displayMode}
                        onChange={partialRefresh}
                        key={"key1112" + Math.random() + "-" + Math.random()} />
                    </span>}
                    {cryptoV2.displayMode == DisplayModeEnum.EDIT && <br />}
                    {cryptoV2.displayMode == DisplayModeEnum.EDIT && <br />}

                    {(cryptoV2.comment || cryptoV2.displayMode == DisplayModeEnum.EDIT) &&
                        <FieldEditorV2
                            tooltip="Commentaire"
                            object={cryptoV2} objectKey="comment"
                            displayMode={cryptoV2.displayMode}
                            rows={props.rows}
                            onChange={partialRefresh} type="comment" key={"key113" + Math.random() + "-" + Math.random()} />
                    }
                </span>
            </TableCell>
            {cryptoV2.displayMode === DisplayModeEnum.VIEW && 
            <TableCell key={"key8" + Math.random() + "-" + Math.random()}
                align="left" className="percLeft crypto-row-evolution">
                {(cryptoV2.price_change_percentage_24h != null && cryptoV2.price_change_percentage_24h !== undefined) && (typeof cryptoV2.price_change_percentage_24h === 'number') &&
                    <Tooltip title="Evolution sur 24h">{(cryptoV2.price_change_percentage_24h === 0) ? (
                        <span className="crypto-percent">= {cryptoV2.price_change_percentage_24h.toFixed(2)}%&nbsp;&nbsp;</span>
                    ) : (cryptoV2.price_change_percentage_24h < 0) ? (
                        <span className="crypto-percent red">{cryptoV2.price_change_percentage_24h.toFixed(2)}%</span>
                    ) : (
                        <span className="crypto-percent green">+{cryptoV2.price_change_percentage_24h.toFixed(2)}%</span>
                    )
                    }
                    </Tooltip>}&nbsp;<Tooltip title="Cours actuel"><span>${cryptoV2.unitPrice && toFixed(cryptoV2.unitPrice, 6)}</span></Tooltip>

                {cryptoV2.unitPrice && cryptoV2.ath && cryptoV2.ath > 0 && <Tooltip title="Pourcentage de l'ATH"><span>&nbsp;&nbsp;{'(' + toFixed(cryptoV2.unitPrice * 100 / cryptoV2.ath, 0) + '%)'}</span></Tooltip>}
                &nbsp;&nbsp;&nbsp;<Tooltip title="ATH"><span>${cryptoV2.ath && toFixed(cryptoV2.ath, 6)}</span></Tooltip>



            </TableCell>
            }
            {cryptoV2.displayMode === DisplayModeEnum.VIEW && <TableCell key={"key9" + Math.random() + "-" + Math.random()}
                align="left" className="percLeft crypto-row-evolution">
                <Tooltip title="Montant actuel du bag">
                    <span className="crypto-percent">{cryptoV2.cryptoBagPrice.toFixed(2)} USDT&nbsp;&nbsp;</span>
                </Tooltip>
                <CryptoRowCryptoV2TargetsSummary cryptoV2={cryptoV2} />
            </TableCell>}
        </TableRow>
        {result}</>);
}

export default CryptoRowCryptoV2;