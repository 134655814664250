"use client";
import "./CryptoBubbles.css";

import * as PIXI from "pixi.js";
import React, { useEffect, useMemo, useState } from "react";
import { Circle, PriceChangePercentage } from "./CryptoBubblesType";
import { PixiUtils, hideBubbles } from "./CryptoBubblesUtil";
import { BubblesUtils, appConfig } from "./CryptoBubblesUtilV2";
import { porttcfolio } from "../../data/CryptoData";
import CryptoFearAndGreed from "../fearandgreed/CryptoFearAndGreed";


const { width, height, maxCircleSize, minCircleSize } = appConfig;

export default function Bubbles() {
  const [circles, setCircles] = useState<Circle[] | null>(null);
  let bubbleSort = PriceChangePercentage.HOUR;

  const appRef = React.useRef<HTMLDivElement>(null);

  let cryptosV2 = porttcfolio.cryptosV2;

  const scalingFactor = useMemo(() => {
    return BubblesUtils.getScalingFactor(cryptosV2, bubbleSort);
  }, [bubbleSort, cryptosV2]);

  useEffect(() => {
    if (cryptosV2) {
      const scalingFactor = BubblesUtils.getScalingFactor(cryptosV2, PriceChangePercentage.HOUR);
      const shapes = BubblesUtils.generateCircles(cryptosV2, scalingFactor);
      setCircles(shapes);
    }
  }, [cryptosV2]);

  useEffect(() => {
    if (!circles) return;
    const imageSprites: PIXI.Sprite[] = [];
    const textSprites: PIXI.Text[] = [];
    const text2Sprites: PIXI.Text[] = [];
    const circleGraphics: PIXI.Sprite[] = [];

    const app = new PIXI.Application({
      width: width,
      height,
      backgroundColor: 0x0e1010
    }) as unknown;

    const appContainer = appRef.current;

    if (appContainer != null) {
      appContainer.appendChild((app as { view: Node }).view);
      appContainer.children[0].addEventListener("click", (e: unknown) => {
        BubblesUtils.handleEmptySpaceClick(e as MouseEvent, circles);
      }
      );
    }

    for (let i = 0; i < circles.length; i++) {
      const circle = circles[i];

      const container = PixiUtils.createContainer(circle);

      const imageSprite = PixiUtils.createImageSprite(circle);
      imageSprites.push(imageSprite);
      container.addChild(imageSprite as unknown as PIXI.DisplayObject);

      const circleGraphic = new PIXI.Sprite(PixiUtils.createGradientTexture(circle.radius * 4, circle.color));
      circleGraphic.anchor.set(0.5);
      circleGraphics.push(circleGraphic);
      container.addChild(circleGraphic as unknown as PIXI.DisplayObject);

      // Create the text
      const text = PixiUtils.createText(circle);
      container.addChild(text as unknown as PIXI.DisplayObject);
      textSprites.push(text);

      // Create the second text
      const text2 = PixiUtils.createText2(circle, PriceChangePercentage.HOUR);

      container.addChild(text2 as unknown as PIXI.DisplayObject);
      text2Sprites.push(text2);


      (app as PIXI.Application).stage.addChild(container as unknown as PIXI.DisplayObject);
    }

    const ticker = BubblesUtils.update(circles, imageSprites, textSprites, text2Sprites, circleGraphics);
    setTimeout(() => {
      (app as PIXI.Application).ticker.add(ticker);
    }, 200);

    return () => { (app as PIXI.Application).ticker.remove(ticker); };
  }, [circles]);

  useEffect(() => {
    if (circles) {
      const max = maxCircleSize;
      const min = minCircleSize;

      circles.forEach((circle) => {
        if (!circle[bubbleSort]) return;

        const radius = Math.abs(Math.floor(circle[bubbleSort] * scalingFactor));
        circle.targetRadius = radius > max ? max : radius > min ? radius : min;
        circle.color = circle[bubbleSort] > 0 ? "green" : "red";
        if (circle.text2) {
          circle.text2.text = (circle.price_change_percentage_1h_in_currency && circle.price_change_percentage_1h_in_currency !== "NULL" ? circle[bubbleSort].toFixed(2) + "%" : "");
        }
      });
    }
  }, [bubbleSort, cryptosV2, circles, scalingFactor]);

  const [daily, setDaily] = useState(false);

  const handleChange = (event: any) => {
    setDaily(event.target.checked);
  };

  /*
  <Typography sx={{ mr: 2 }}>Journalier</Typography>
      <Switch
          checked={daily}
          onChange={handleChange}
          color="primary"
        />
        <Typography sx={{ mr: 2 }}>Bimensuel</Typography>
  */

  return (
    <div id="bubbles" className="flex rounded px-2 overflow-hidden bg-zinc-900 md:flex-col flex-col-reverse">
      <div style={{ height: "100%" }} className="bg-zinc-900 w-full overflow-hidden border-2 rounded border-gray-800" ref={appRef}></div>
      <div className="bubblesHeader">
        <div className="bubblesHeaderBox">

          <button className="ttcButton" onClick={hideBubbles}>Accéder à Cryptoverview</button>
          <div>ou cliquez sur une bulle pour voir sa fiche dans CoinGecko
          </div>
        </div>
          <div className="cryptoFearAndGreedDiv">
            <CryptoFearAndGreed />
            </div>
      </div>
    </div>
  );
}
