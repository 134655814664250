import { CryptoExchangeV2, CryptoSummary, CryptoTargetTotal, CryptoV2, CurrencyEnum, DisplayModeEnum, Exchange, Porttcfolio, StatusEnum, TargetDataV2 } from "../v2/data/CryptoDataTypeV2";
import { generateTtcId } from "../v2/util/PortfolioUtil";
import { IMPOTS_DEFAULT_PERC } from "./CryptoConfig";
import { getCurrencyRate, getFearAndGreed } from "./CryptoGeeki";

export let exchangesDef = { exchanges: [] };

export let porttcfolio: Porttcfolio = {
  investment: 0,
  impotsPerc: 33,
  cryptosV2: [],
  exchanges: [],
  transient: {
    exchanges: [], cryptos: [], cryptoTargets: [], cryptoSummary: [], totalTarget: 0
    ,totalGains:0, allLoaded:true, totalInvested: 0, totalReInvested: 0, totalSold: 0, totalSoldMinusReinvested: 0, totalBeforeTax: 0, totalTax: 0, totalAfterTax: 0
  }
};

export const initTransientDataV2 = (headerRef: any) => {
  resetTransient();

  for (let cryptoV2 of porttcfolio.cryptosV2) {
    for (let cryptoExchangeV2 of cryptoV2.exchanges) {

      let price = cryptoExchangeV2.exchangeBagPrice ? parseFloat(cryptoExchangeV2.exchangeBagPrice.toFixed(2)) : 0;
      let trouve = false;
      if (porttcfolio.transient != null && porttcfolio.transient !== undefined) {
        for (let exc of porttcfolio.transient.exchanges) {
          if (exc.exchange === cryptoExchangeV2.name.toUpperCase()) {
            exc.value = exc.value + Number(price.toFixed(0));
            exc.label = cryptoExchangeV2.name.toUpperCase() + ' $' + exc.value;
            exc.exchangeBagPrice = exc.exchangeBagPrice + (cryptoExchangeV2.exchangeBagPrice == null ? 0 : cryptoExchangeV2.exchangeBagPrice);
            trouve = true;
          }
        }

        if (!trouve) {
          porttcfolio.transient.exchanges.push({
            exchange: cryptoExchangeV2.name.toUpperCase()
            , exchangeBagPrice: (cryptoExchangeV2.exchangeBagPrice == null ? 0 : cryptoExchangeV2.exchangeBagPrice)
            , value: Number(price.toFixed(0))
            , label: cryptoExchangeV2.name.toUpperCase() + ' $' + price
            , label2: cryptoExchangeV2.name.toUpperCase()
          });
        }

        trouve = false;
        for (let exc of porttcfolio.transient.cryptos) {
          if (exc.symbol === cryptoV2.symbol.toUpperCase()) {
            exc.value = exc.value + Number(price.toFixed(0));
            exc.label = cryptoV2.symbol.toUpperCase() + ' $' + exc.value;
            trouve = true;
          }
        }

        if (!trouve) {
          porttcfolio.transient.cryptos.push({
            symbol: cryptoV2.symbol.toUpperCase()
            , value: Number(price.toFixed(0))
            , label: cryptoV2.symbol.toUpperCase() + ' $' + price
            , label2: cryptoV2.symbol.toUpperCase()
          });
        }


        let total = 0;
        let sumPerc = 0;

        for (let i = 0; i < cryptoExchangeV2.targets.length; i++) {
          let rate = (cryptoExchangeV2.targets[i].rate == null || cryptoExchangeV2.targets[i].rate === undefined ? null : cryptoExchangeV2.targets[i].rate);
          let targetPrice = calculPrice(cryptoExchangeV2.targets[i].bag, rate);
          total += Number(targetPrice ? targetPrice.toFixed(20) : 0);
          let bagPerc = cryptoExchangeV2.targets[i].bagPerc === undefined ? 0 : cryptoExchangeV2.targets[i].bagPerc;
          if (bagPerc !== undefined) {
            sumPerc += bagPerc;
          }

          //todo cst calcul vendu et reinvest

        }
        if (sumPerc < 100 && cryptoExchangeV2.targets.length > 0) {
          let rate = (cryptoExchangeV2.targets[cryptoExchangeV2.targets.length - 1].rate == null || cryptoExchangeV2.targets[cryptoExchangeV2.targets.length - 1].rate === undefined ? null : cryptoExchangeV2.targets[cryptoExchangeV2.targets.length - 1].rate);
          let bag = cryptoExchangeV2.targets[cryptoExchangeV2.targets.length - 1].bag;
          if (bag !== undefined) {
            let targetPrice = calculPrice(bag * (100 - sumPerc) / 100, rate);
            total += Number(targetPrice ? targetPrice.toFixed(20) : 0);
          }
        }

        trouve = false;
        for (let ct of porttcfolio.transient.cryptoTargets) {
          if (ct.symbol.toUpperCase() === cryptoV2.symbol.toUpperCase()) {
            ct.value = ct.value + Number(total.toFixed(0));
            ct.label = cryptoV2.symbol.toUpperCase() + ' $' + ct.value;
            trouve = true;
          }
        }

        if (!trouve) {

          let cryptoTarget: CryptoTargetTotal = {
            symbol: cryptoV2.symbol
            , id: cryptoV2.symbol + Math.random()
            , value: Number(total.toFixed(0))
            , label: cryptoV2.symbol + ' $' + total.toFixed(0)
            , label2: cryptoV2.symbol
          };
          porttcfolio.transient.cryptoTargets.push(cryptoTarget);
        }
        porttcfolio.transient.totalTarget += total;

      }
    }

  }

  getCurrencyRate("USD").then((res: any) => {
    if (porttcfolio.transient != null && porttcfolio.transient !== undefined) {
      porttcfolio.transient.rateEurUsd = Number(res.data.rate);
    }
  });

  if(porttcfolio.currency && porttcfolio.currency !== CurrencyEnum.EUR){
    getCurrencyRate(porttcfolio.currency).then((res: any) => {
      if (porttcfolio.transient != null && porttcfolio.transient !== undefined) {
        porttcfolio.transient.rateEurUserCurrency = Number(res.data.rate);
      }
    });
  }else{
    porttcfolio.currency=CurrencyEnum.EUR;
    if (porttcfolio.transient != null && porttcfolio.transient !== undefined) {
    porttcfolio.transient.rateEurUserCurrency = 1;
    }
  }

  getFearAndGreed().then((res:any) => {
porttcfolio.transient.fearAndGreed=res.data.split("-")[0];
  });

  

  initTransientTotals();



  if (headerRef != null && headerRef.current != null) {
    (headerRef.current).forceRefresh();
  }
}


export const initTransientTotals = () => {
  let totalSold = 0;
  let totalReInvested = 0;
  let totalBeforeTax = 0;

  let investment = (porttcfolio.investment ? Number(porttcfolio.investment) : 0);
  let summary: CryptoSummary[] = [];
  if (porttcfolio.transient) {
    porttcfolio.transient.cryptoSummary = summary;
  }
  for (let cryptoV2 of porttcfolio.cryptosV2) {
    for (let cryptoExchangeV2 of cryptoV2.exchanges) {

      let itemSummary: CryptoSummary = { symbol: '', totalLeft: 0, sold: 0, reinvested: 0 };
      for (let cs of summary) {
        if (cs.symbol === cryptoV2.symbol) {
          itemSummary = cs;
          break;
        }
      }
      if (itemSummary.symbol === '') {
        itemSummary = { symbol: cryptoV2.symbol, totalLeft: 0, sold: 0, reinvested: 0 };
        summary.push(itemSummary);
      }


      if (cryptoExchangeV2.targets.length == 0) {
        itemSummary.totalLeft += (cryptoExchangeV2.exchangeBagPrice == null || cryptoExchangeV2.exchangeBagPrice === undefined ? 0 : cryptoExchangeV2.exchangeBagPrice);
      } else {
        for (let i = 0; i < cryptoExchangeV2.targets.length; i++) {
          let target = cryptoExchangeV2.targets[i];
          if (target.status === StatusEnum.Sold) {
            totalSold += Number(target.price);
            itemSummary.sold += Number(target.price);
            if (target.reinvestedAmount && Number(target.reinvestedAmount) > 0) {
              totalReInvested += Number(target.reinvestedAmount);
              itemSummary.reinvested += Number(target.reinvestedAmount);
            }
          } else {
            itemSummary.totalLeft += initTransientTargetPrice(cryptoV2, cryptoExchangeV2, target);
          }
          totalBeforeTax += initTransientTargetPrice(cryptoV2, cryptoExchangeV2, target);
        }
      }
    }
  }


  if (porttcfolio.transient) {

    totalBeforeTax = 0;
    totalSold = 0;
    totalReInvested = 0;
    for (let cryptoSummary of summary) {
      totalSold += cryptoSummary.sold;
      totalReInvested += cryptoSummary.reinvested;
      totalBeforeTax += (cryptoSummary.totalLeft + cryptoSummary.sold - cryptoSummary.reinvested);
    }

    porttcfolio.transient.totalInvested = investment;
    porttcfolio.transient.totalSold = totalSold;
    porttcfolio.transient.totalReInvested = totalReInvested;
    porttcfolio.transient.totalSoldMinusReinvested = totalSold - totalReInvested;
    porttcfolio.transient.totalBeforeTax = totalBeforeTax;

    let impotsPerc = (porttcfolio.impotsPerc ? Number(porttcfolio.impotsPerc) : IMPOTS_DEFAULT_PERC);;

    let totalTax = (totalBeforeTax - investment) * impotsPerc / 100;
    totalTax = (totalTax < 0 ? 0 : totalTax);
    porttcfolio.transient.totalTax = totalTax;

    let totalAfterTax = totalBeforeTax - totalTax;
    porttcfolio.transient.totalAfterTax = totalAfterTax;

    let totalGains = totalTax - investment - totalTax;
    totalGains = (totalGains < 0 ? 0 : totalGains);
    porttcfolio.transient.totalGains = totalGains;
  }
}

export const initTransientTargetPrice = (cryptoV2: CryptoV2, cryptoExchangeV2: CryptoExchangeV2, target: TargetDataV2) => {
  let bagOfTarget = (target.bagPerc ? Number(cryptoExchangeV2.bag) * Number(target.bagPerc) / 100 : 0);
  if (target.status === StatusEnum.Sold) {
    let soldTotal = Number(bagOfTarget) * Number(target.rate);
    if (target.reinvestedAmount) {
      return Number(soldTotal) - Number(target.reinvestedAmount);
    } else {
      return Number(soldTotal);
    }
  } else {
    return (cryptoV2.unitPrice ? (Number(cryptoV2.unitPrice) * bagOfTarget) : 0)
  }
}

export const resetTransient = () => {

  if (porttcfolio.transient != null) {
    porttcfolio.transient.exchanges = [];
    porttcfolio.transient.cryptos = [];
    porttcfolio.transient.cryptoTargets = [];
    porttcfolio.transient.totalTarget = 0;
  } else {
    porttcfolio.transient = {
      exchanges: [], cryptos: [], cryptoTargets: [], cryptoSummary: [], totalTarget: 0
      ,totalGains:0, allLoaded:true,  totalInvested: 0, totalReInvested: 0, totalSold: 0, totalSoldMinusReinvested: 0, totalBeforeTax: 0, totalTax: 0, totalAfterTax: 0
    };
  }
}

export const reloadPorttcfolioFromJsonV2 = (value: string, headerRef: any) => {
  try {
    if (value.indexOf("investment") >= 0) {
      porttcfolio = JSON.parse(value);
      initTransientDataV2(headerRef);
      cleanExchanges();
    } else {
      porttcfolio = {
        investment: 0,
        impotsPerc: 33,
        cryptosV2: [],
        exchanges: [],
        transient: {
          exchanges: [], cryptos: [], cryptoTargets: [], cryptoSummary: [], totalTarget: 0
          , totalGains:0, allLoaded:true, totalInvested: 0
          , totalReInvested: 0, totalSold: 0, totalSoldMinusReinvested: 0, totalBeforeTax: 0, totalTax: 0, totalAfterTax: 0
        }
      };
    }
  } catch (e) {

    if (value.indexOf("investment") >= 0) {
      let value1 = '{"impotsPerc": 33, "exchanges": [], "transient": { "exchanges": [], "cryptos": [], "cryptoTargets": [], "totalTarget": 0 },' + value.substring(1);
      value1 = value1.replaceAll("totalPrice", "\"loaded\":false,\"automaticUpdate\":true,\"updatedFromExchange\":false,\"bagPrice");
      value1 = value1.replaceAll(',""loaded"', ',"loaded"');

      const regex = /,"notificationHigh\d+":\d+/g;
      value1 = value1.replace(regex, '');

      try {
        porttcfolio = JSON.parse(value1);
        initTransientDataV2(headerRef);
        cleanExchanges();

      } catch (ex) {
        console.log(ex);
        alert("Mauvais nouveau format");
        porttcfolio = {
          investment: 0,
          impotsPerc: 33,
          cryptosV2: [],
          exchanges: [],
          transient: {
            exchanges: [], cryptos: [], cryptoTargets: [], cryptoSummary: [], totalTarget: 0
            , totalGains:0, allLoaded:true, totalInvested: 0, totalReInvested: 0, totalSold: 0, totalSoldMinusReinvested: 0, totalBeforeTax: 0, totalTax: 0, totalAfterTax: 0
          }
        };
      }
    } else {
      porttcfolio = {
        investment: 0,
        impotsPerc: 33,
        cryptosV2: [],
        exchanges: [],
        transient: {
          exchanges: [], cryptos: [], cryptoTargets: [], cryptoSummary: [], totalTarget: 0
          , totalGains:0, allLoaded:true, totalInvested: 0, totalReInvested: 0, totalSold: 0, totalSoldMinusReinvested: 0, totalBeforeTax: 0, totalTax: 0, totalAfterTax: 0
        }
      };
    }



  }
  for(let cryptoV2 of porttcfolio.cryptosV2){
      cryptoV2.displayMode=DisplayModeEnum.VIEW;
  }
}

const cleanExchanges = () => {
  let data = [];
  for (let i = 0; i < porttcfolio.exchanges.length; i++) {
    let obj = porttcfolio.exchanges[i];
    let trouve = false;
    for (let j = 0; j < data.length; j++) {
      if (data[j].exchange === obj.exchange.toUpperCase()) {
        trouve = true;
        break;
      }
    }
    if (!trouve) {
      data.push(obj);
      for (let j = 0; j < porttcfolio.exchanges.length; j++) {
        let myObj: Exchange = porttcfolio.exchanges[j];
        if (myObj.exchange === obj.exchange.toUpperCase()) {
          obj.editable = myObj.editable;
        }
      }

    }
  }
  porttcfolio.exchanges = [];
  for (let j = 0; j < data.length; j++) {
    porttcfolio.exchanges.push(data[j]);
  }

}



export const initDummyPortfolio = () => {

  porttcfolio =
  {
    investment: 0,
    impotsPerc: 33,
    cryptosV2: [
      {
        "ttcId": "ttc1",
        "symbol": "TAO",
        "name": "bittensor",
        "coingeckoId": "bittensor",
        "cryptoBagPrice": 0,
        "comment": "nouvel ATH attendu",
        "exchanges": [
          { "name": "BLOFIN", "bag": 213, automaticUpdate: true, updatedFromExchange: false, exchangeBagPrice: 0, "targets": [{ "rate": 700,"status":StatusEnum.Init }], ttcId: generateTtcId() },
          { "name": "BYBIT", "bag": 456, automaticUpdate: true, updatedFromExchange: false, exchangeBagPrice: 0, "targets": [{ "rate": 1000,"status":StatusEnum.Init }, { "rate": 2000,"status":StatusEnum.Init }], ttcId: generateTtcId() }
        ]
      },
      {
        "ttcId": "ttc2",
        "symbol": "BTC",
        "name": "Bitcoin",
        "coingeckoId": "Bitcoin",
        "cryptoBagPrice": 0,
        "exchanges": [
          { "name": "GATE.IO", "bag": 123, automaticUpdate: true, updatedFromExchange: false, exchangeBagPrice: 0, "targets": [{ "rate": 100000,"status":StatusEnum.Init }], ttcId: generateTtcId() },
          { "name": "METAMASK", "bag": 654, automaticUpdate: true, updatedFromExchange: false, exchangeBagPrice: 0, "targets": [{ "rate": 70000, "status": StatusEnum.Sold }, { "rate": 150000,"status":StatusEnum.Init }], ttcId: generateTtcId() }
        ]
      },
      {
        "ttcId": "tt3",
        "symbol": "ETH",
        "name": "Ethereum",
        "coingeckoId": "Ethereum",
        "cryptoBagPrice": 0,
        "exchanges": [
          { "name": "MEXC", "bag": 777, automaticUpdate: true, updatedFromExchange: false, exchangeBagPrice: 0, "targets": [], ttcId: generateTtcId() }
        ]
      },
      {
        "ttcId": "ttc4",
        "symbol": "XRP",
        "name": "XRP",
        "coingeckoId": "ripple",
        "cryptoBagPrice": 0,
        "exchanges": [
          { "name": "GATE.IO", "bag": 11, automaticUpdate: true, updatedFromExchange: false, exchangeBagPrice: 0, "targets": [{ "rate": 3,"status":StatusEnum.Init }], ttcId: generateTtcId() },
          { "name": "METAMASK", "bag": 22, automaticUpdate: true, updatedFromExchange: false, exchangeBagPrice: 0, "targets": [], ttcId: generateTtcId() }
        ]
      },
      {
        "ttcId": "ttc5",
        "symbol": "AKT",
        "name": "akash network",
        "coingeckoId": "akash-network",
        "cryptoBagPrice": 0,
        "exchanges": [
          { "name": "GATE.IO", "bag": 99, automaticUpdate: true, updatedFromExchange: false, exchangeBagPrice: 0, "targets": [{ "rate": 7 ,"status":StatusEnum.Init}, { "rate": 8,"status":StatusEnum.Init }], ttcId: generateTtcId() },
          { "name": "BYBIT", "bag": 88, automaticUpdate: true, updatedFromExchange: false, exchangeBagPrice: 0, "targets": [{ "rate": 7,"status":StatusEnum.Init }, { "rate": 8 ,"status":StatusEnum.Init}], ttcId: generateTtcId() }
        ]
      }
    ],
    exchanges: [],
    transient: {
      exchanges: [], cryptos: [], cryptoTargets: [], cryptoSummary: [], totalTarget: 0
      , totalGains:0, allLoaded:true, totalInvested: 0, totalReInvested: 0, totalSold: 0, totalSoldMinusReinvested: 0, totalBeforeTax: 0, totalTax: 0, totalAfterTax: 0
    }
  };
}


export const calculPrice = (bag: number | undefined, rate: number | null) => {
  if (rate == null) {
    return 0;
  }
  if (bag !== undefined) {
    let result = bag * rate;
    if (Number.isNaN(result) || result === Infinity) {
      return 0;
    } else {
      return result;
    }
  } else {
    return 0;
  }
}

export const updateExchange = (row: any) => {
  if (row.editable) {
    let trouve = false;
    if (porttcfolio.exchanges) {
      for (let i = 0; i < porttcfolio.exchanges.length; i++) {
        let obj = porttcfolio.exchanges[i];
        if (obj.exchange.toUpperCase() === row.exchange.toUpperCase()) {
          obj.key = row.key;
          obj.secret = row.secret;
          obj.passphrase = row.passphrase;
          obj.exclusions = row.exclusions;

          trouve = true;
        }
      }
    } else {
      porttcfolio.exchanges = [];
    }
    if (!trouve) {
      let obj: Exchange = { exchange: row.exchange, key: row.key, secret: row.secret, passphrase: row.passphrase, exclusions: row.exclusions, editable: row.editable };
      porttcfolio.exchanges.push(obj);
    }
  }
}

export const toFixed = (value: any, precision: number) => {
  if (value == null || value === undefined) {
    return null;
  } else if (isNaN(value)) {
    return Number(value).toFixed(precision);
  } else {
    let valueNumber: number = Number(value);
    return valueNumber.toFixed(precision);
  }
}


