import { useState } from "react";
import { Tooltip } from "@mui/material";
import { updatePortfolioInBrowserCache } from "../../data/CryptoCache";
import { CryptoExchangeV2, CryptoV2, DisplayModeEnum } from "../data/CryptoDataTypeV2";
import { reprocessTargetsData } from "../../data/CryptoProcessData";

const TargetEditorV2 = (props: any) => {
    let [value] = useState(props);
    let cryptoV2:CryptoV2 = value.cryptoV2;
    let cryptoExchangeV2:CryptoExchangeV2=value.cryptoExchangeV2;
    let targetData:any=cryptoExchangeV2.targets[props.targetPos];
    if (cryptoV2.displayMode === DisplayModeEnum.VIEW) {
        return <Tooltip className={"fieldEditor"} title={props.tooltip}><span className={"fieldEditor viewMode "+props.className}>{props.type === "currency" && targetData[props.prop] ? "$" : ""}{targetData[props.prop]}{props.type === "percentage" && targetData[props.prop] ? "%" : ""}</span></Tooltip>
    } else {
        let myClassName = "fieldEditor " + props.type;
        const blurMe = (newValue: any) => {   
            targetData[props.prop] = newValue;
            reprocessTargetsData(cryptoV2,cryptoExchangeV2);
            updatePortfolioInBrowserCache();
        }
        return (<Tooltip className={"fieldEditor"} title={props.tooltip}><span className={"fieldEditor editMode "+props.className}>{props.type === "currency" ? "$" : ""}<input type="text" defaultValue={targetData[props.prop]}
            onBlur={e => blurMe(e.target.value)} className={myClassName} />{props.type === "percentage" ? "%" : ""}</span></Tooltip>);
    }
}

export default TargetEditorV2;