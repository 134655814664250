import { LineChart } from '@mui/x-charts/LineChart';
import { Tooltip } from '@mui/material';
import { calculPrice, porttcfolio } from '../../data/CryptoData';
import { StatusEnum } from '../data/CryptoDataTypeV2';

interface ChildProps {
    investment?: number
}

const headerGraphColors=[
    '#66c2a5',
    '#fc8d62',
    '#8da0cb',
    '#e78ac3',
    '#a6d854',
    '#ffd92f',
    '#e5c494',
    '#b3b3b3',
];

const CryptoHeaderGraphV2: React.FC<ChildProps> = ({ investment }) => {
    if (porttcfolio && porttcfolio.cryptosV2 && porttcfolio.cryptosV2.length>0) {
        let xAxis = [];
        let partialData: any = [];
        let mixedData: any = [];
        let fullData: any = [];
        let soldData: any = [];
        let impotData: any = [];
        let gainData: any = [];
        let netData: any = [];
        for (let i = 1; i <= 100; i++) {
            xAxis.push(i);
        }
        fillGraph(investment, mixedData, partialData, fullData, soldData, impotData, gainData, netData);

        const CustomItemTooltip = ({ ...props }) => (
            <Tooltip title="Ajouter" arrow>
                <>ajouter</>
            </Tooltip>

        );


        if(porttcfolio.cryptosV2.length>0){
            return <div className='crypto-header-graph'>
            <LineChart
                xAxis={[{ data: xAxis }]}
                dataset={mixedData}
                slots={{
                    itemContent: CustomItemTooltip
                }}
                series={[
                    {
                        showMark: false,
                        type: 'line',
                        dataKey: 'objectif',
                        area: true,
                        valueFormatter: (v: any) => {
                            return `Total: ${v} USDT`;
                        },

                    },

                    {
                        showMark: false,
                        type: 'line',
                        dataKey: 'net',
                        area: true,

                        valueFormatter: (v: any) => {
                            return `Net: ${v} USDT`;
                        },

                    },
                    {
                        showMark: false,
                        type: 'line',
                        dataKey: 'gains',
                        area: true,

                        valueFormatter: (v: any) => {
                            return `Gains: ${v} USDT`;
                        },

                    },
                    {
                        showMark: false,
                        type: 'line',
                        dataKey: 'impots',
                        area: true,

                        valueFormatter: (v: any) => {
                            return `Impots: ${v} USDT`;
                        },

                    },
                    {
                        showMark: false,
                        type: 'line',
                        dataKey: 'vendu',
                        area: true,

                        valueFormatter: (v: any) => {
                            return `Vendu: ${v} USDT`;
                        },

                    },
                ]}
                margin={{
                    left: 80,
                    right: 10,
                    top: 20,
                    bottom: 50,
                }}
                width={1000}
                height={180}
                colors={headerGraphColors}
            />
        </div>;
        }else{
            return <></>;
        }
    } else {
        return <></>;
    }
};

const fillGraph = (investment1: number | undefined, mixedData: any, partialData: any, fullData: any, soldData: any, impotData: any, gainData: any, netData: any) => {
    let investment = 0;
    if (investment1 !== undefined) {
        investment = investment1;
    }

    for (let i = 0; i < porttcfolio.cryptosV2.length; i++) {
        let cryptosV2 = porttcfolio.cryptosV2[i];
        for (let k = 0; k < cryptosV2.exchanges.length; k++) {
            let exc = cryptosV2.exchanges[k];
            for (let j = 0; j < exc.targets.length; j++) {
                let target = exc.targets[j];
                target.graphProcessed = false;
            }
        }

    }
    let fullTotal = 0;
    for (let k = 1; k <= 100; k++) {
        let partialTotal = 0;
        let soldTotal = 0;

        for (let i = 0; i < porttcfolio.cryptosV2.length; i++) {
            let cryptosV2 = porttcfolio.cryptosV2[i];
            for (let l = 0; l < cryptosV2.exchanges.length; l++) {
                let exc = cryptosV2.exchanges[l];
                let sumPerc = 0;
                for (let j = 0; j < exc.targets.length; j++) {
                    let target = exc.targets[j];
                    let bagPerc = 0;
                    if (target.bagPerc !== undefined) {
                        bagPerc = target.bagPerc;
                    }
                    
                    let targetPrice = 0;
                    if (target.price !== undefined) {
                        targetPrice = target.price;
                    }
                    if (sumPerc + bagPerc <= k) {
                        sumPerc += bagPerc;
                        if (!target.graphProcessed) {
                            target.graphProcessed = true;
                            fullTotal += targetPrice;
                        }
                        if (StatusEnum.Sold===target.status) {
                            soldTotal += targetPrice;
                            partialTotal += targetPrice;
                            if (target.reinvestedAmount) {
                                soldTotal -= target.reinvestedAmount;
                                partialTotal -= target.reinvestedAmount;
                            }
                        } else {
                            partialTotal += targetPrice;
                        }
                    } else {
                        if (!target.graphProcessed) {
                            target.graphProcessed = true;
                            for (let l = j + 1; l < exc.targets.length; l++) {
                                let rate: number | null = (exc.targets[l].rate === undefined ? null : target.rate);
                                fullTotal += calculPrice(exc.targets[l].bag, rate);
                            }
                        }
                        break;
                    }
                }
            }

        }
        let impotTotal = (partialTotal - investment) * 33 / 100;
        if (impotTotal < 0) {
            impotTotal = 0;
        }
        let gainTotal = partialTotal - investment - impotTotal;
        if (gainTotal < 0) {
            gainTotal = 0;
        }
        let netTotal = partialTotal - impotTotal;
        impotData.push(impotTotal);
        gainData.push(gainTotal);
        netData.push(netTotal);
        partialData.push(partialTotal);
        fullData.push(fullTotal);
        soldData.push(soldTotal);
        mixedData.push({ id: partialTotal | 0, value: partialTotal | 0, objectif: partialTotal | 0, impots: impotTotal | 0, gains: gainTotal | 0, net: netTotal | 0, vendu: soldTotal | 0 });
    }
}

export default CryptoHeaderGraphV2;