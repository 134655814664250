import "../../CryptoTable.css";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import RemoveIcon from '@mui/icons-material/RemoveCircle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { IconButton, Tooltip } from "@mui/material";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import BackHandIcon from '@mui/icons-material/BackHand';
import { useState } from "react";
import CryptoRowTargetV2 from './CryptoRowTargetV2';
import { updatePortfolioInBrowserCache } from '../../data/CryptoCache';
import { calculPrice, exchangesDef, porttcfolio, toFixed } from '../../data/CryptoData';
import CryptoGauge from '../../array/CryptoGauge';
import { CryptoExchangeV2, CryptoV2, DisplayModeEnum, ExchangeDef, StatusEnum } from '../data/CryptoDataTypeV2';
import CryptoRowTargetSummary from '../../array/CryptoRowTargetSummary';
import CryptoRowTargetScrollerV2 from './CryptoRowTargetScrollerV2';
import FieldEditorV2 from "../editor/FieldEditorV2";
import transImg from '../../../trans.gif';
import { exchangesConfigArray } from "../../data/CryptoConfig";
import { isStableCoin } from "../util/PortfolioUtil";


const CryptoRowExchangeV2 = (props: any) => {
    const [updated, setUpdated] = useState(true);

    let cryptoExchangeV2 = props.cryptoExchangeV2;
    let cryptoV2 = props.cryptoV2;

    const onRemove = () => {
        props.onRemove(cryptoExchangeV2.ttcId);
    }
    const onRemoveTarget = (targetPos: number) => {
        props.onRemoveTarget(cryptoExchangeV2.ttcId, targetPos);
    }

    const partialRefresh = () => {
        props.partialRefresh(true);
    }

    const canSwitchToAutomatic = (row: CryptoExchangeV2) => {
        for (let i = 0; i < row.targets.length; i++) {
            if (StatusEnum.Init !== row.targets[i].status) {
                return false;
            }
        }
        return true;
    }

    if (isStableCoin(cryptoV2.symbol)) {
        cryptoExchangeV2.targets = [];
    }


    let targets = [];
    for (let i = 0; i < cryptoExchangeV2.targets.length; i++) {
        targets.push(<CryptoRowTargetV2 key={"key02" + Math.random() + "-" + + Math.random()}
            cryptoV2={cryptoV2} targetPos={i} cryptoExchangeV2={cryptoExchangeV2} onAdd={props.onAddTarget}
            rows={props.rows}
            onRemoveTarget={onRemoveTarget}
            partialRefresh={partialRefresh} fullRefresh={props.fullRefresh}
        />);
    }
    targets.push(<CryptoRowTargetV2 key={"key03" + Math.random() + "-" + Math.random()}
        isAddTarget={true}
        className="addTarget"
        cryptoV2={cryptoV2} targetPos={cryptoExchangeV2.targets.length + 1} cryptoExchangeV2={cryptoExchangeV2} onAdd={props.onAddTarget}
        rows={props.rows}
        onRemoveTarget={onRemoveTarget}
        partialRefresh={partialRefresh} fullRefresh={props.fullRefresh}
    />);
    let coeff = null;
    if (cryptoExchangeV2.invested && cryptoExchangeV2.exchangeBagPrice) {
        coeff = cryptoExchangeV2.exchangeBagPrice / cryptoExchangeV2.invested;
    }

    const renderBuyIcon = (cryptoExchangeV2: CryptoExchangeV2, cryptoV2: CryptoV2) => {
        if (cryptoV2.displayMode === DisplayModeEnum.VIEW) {
            for (let j = 0; j < exchangesConfigArray.length; j++) {
                let myObj = exchangesConfigArray[j];
                if (myObj.exchange === cryptoExchangeV2.name.toUpperCase() && myObj.buyUrl) {
                    return <Tooltip title="Acheter crypto">
                        <IconButton onClick={() => window.open(myObj.buyUrl ? myObj.buyUrl.replaceAll("{placeholder}", cryptoV2.symbol) : '')}>
                            <ShoppingBasketIcon />
                        </IconButton>
                    </Tooltip>;
                }
            }
        } 
        return '';
    }

    const switchAutomatic = (row: any) => {
        row.automaticUpdate = true;
        for (let i = 0; i < porttcfolio.cryptosV2.length; i++) {
            let obj = porttcfolio.cryptosV2[i];
            for (let j = 0; j < obj.exchanges.length; j++) {
                let exc = obj.exchanges[j];
                if (exc.ttcId === row.ttcId) {
                    exc.automaticUpdate = true;
                }
            }
        }
        updatePortfolioInBrowserCache();
        setUpdated(!updated);
    }


    const switchManual = (row: any) => {
        row.automaticUpdate = false;
        for (let i = 0; i < porttcfolio.cryptosV2.length; i++) {
            let obj = porttcfolio.cryptosV2[i];
            for (let j = 0; j < obj.exchanges.length; j++) {
                let exc = obj.exchanges[j];
                if (exc.ttcId === row.ttcId) {
                    exc.automaticUpdate = false;
                }
            }
        }
        updatePortfolioInBrowserCache();
        setUpdated(!updated);
    }

    const removeWarning = (row: any) => {
        for (let i = 0; i < porttcfolio.cryptosV2.length; i++) {
            let cryptoV2 = porttcfolio.cryptosV2[i];
            for (let j = 0; j < cryptoV2.exchanges.length; j++) {
                let exc = cryptoV2.exchanges[j];
                if (exc.ttcId === row.ttcId) {
                    exc.updatedFromExchange = false;
                }
            }
        }
        row.updatedFromExchange = false;
        updatePortfolioInBrowserCache();
        setUpdated(!updated);
    }


    let totalAmount = 0;
    for (let i = 0; i < cryptoExchangeV2.targets.length; i++) {
        let rate = (cryptoExchangeV2.targets[i].rate == null || cryptoExchangeV2.targets[i].rate === undefined ? null : cryptoExchangeV2.targets[i].rate);
        let targetPrice = calculPrice(cryptoExchangeV2.targets[i].bag, rate);
        totalAmount += targetPrice;
    }


    let exchangeImage = null;
    if (cryptoExchangeV2.name) {
        for (let def of exchangesDef.exchanges) {
            if ((def as ExchangeDef).name === cryptoExchangeV2.name.toUpperCase()) {
                exchangeImage = (def as ExchangeDef).image;
                break;
            }
        }
    }

    return (
        <TableRow
            key={"key07" + Math.random() + "-" + Math.random()}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            className={"crypto-row exhcange filterable filterable-"+cryptoV2.symbol+" filterable-"+cryptoExchangeV2.name.replaceAll(" ","")}
        >
            <TableCell key={"key1311" + Math.random() + "-" + Math.random()} align="center" className="percLeft"></TableCell>
            {cryptoV2.displayMode === DisplayModeEnum.EDIT &&
                <TableCell key={"key13" + Math.random() + "-" + Math.random()} align="center" className="removeRow percLeft">
                    <Tooltip title="Supprimer ligne">
                        <IconButton onClick={onRemove}>
                            <RemoveIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            }
            <TableCell key={"key061" + Math.random() + "-" + Math.random()} component="td" scope="row" className="crypto-cell">
                {renderBuyIcon(cryptoExchangeV2, cryptoV2)}
<br/>
{!canSwitchToAutomatic(cryptoExchangeV2) && <Tooltip title="Impossible de changer car ordres de vente en cours/réalisés"><BackHandIcon /></Tooltip>}
{cryptoExchangeV2.automaticUpdate && canSwitchToAutomatic(cryptoExchangeV2) && <IconButton onClick={() => switchManual(cryptoExchangeV2)}>
    <AutorenewIcon /></IconButton>}
{(!cryptoExchangeV2.automaticUpdate) && canSwitchToAutomatic(cryptoExchangeV2) && <IconButton onClick={() => switchAutomatic(cryptoExchangeV2)}>
    <BackHandIcon /></IconButton>}
    <br/>
    {cryptoExchangeV2.updatedFromExchange && <Tooltip title="Le bag a été mis à jour par la mise à jour automatique. Veuillez passer en manuel ou modifier éventuellement le montant investi. Pour ne plus voir ce message, cliquez sur cette icone." ><IconButton className="iconWarning" onClick={() => removeWarning(cryptoExchangeV2)}>
                        <WarningAmberIcon /></IconButton></Tooltip>}
                <CryptoRowTargetSummary row={cryptoExchangeV2} key={"key03" + Math.random() + "-" + Math.random()} />
                            
            </TableCell>
            <TableCell key={"key06" + Math.random() + "-" + Math.random()} component="td" scope="row" className="crypto-cell">
                <div className="crypto-row-symbol">
                    <span style={{ width: "40px", display: "inline-block" }}>    
                        {exchangeImage && <span className="crypto-cell-V2-img"><img src={exchangeImage} alt="crypto" /></span>}
                        {(!exchangeImage) && <span className="crypto-cell-V2-img"><img src={transImg} alt=""/></span>}
                    </span>
                    <span style={{ width: "calc (100% - 40px)", display: "inline-block" }}>
                        <FieldEditorV2
                            tooltip="Plateforme"
                            className="fixedWidth"
                            object={cryptoExchangeV2} objectKey="name"
                            displayMode={cryptoV2.displayMode}
                            rows={props.rows}
                            onChange={partialRefresh} type="text" key={"key11" + Math.random() + "-" + Math.random()} />
                    </span>
                </div>

                <div style={{ width: "100%" }}><FieldEditorV2
                    className="fixedWidth"
                    tooltip="Quantité de cryptomonnaie"
                    object={cryptoExchangeV2} objectKey="bag" label="bag:"
                    rows={props.rows}
                    displayMode={cryptoV2.displayMode}
                    onChange={partialRefresh}
                    type="number" key={"key10" + Math.random() + "-" + Math.random()} />
                </div>
                <div style={{ width: "100%" }}>
                    <span style={{ width: "20%", display: "inline-block" }}>
                        <FieldEditorV2
                            tooltip="Montant investi en USDT"
                            className={cryptoV2.displayMode === DisplayModeEnum.VIEW ? "" : "fixedWidth"}
                            object={cryptoExchangeV2} objectKey="invested"
                            rows={props.rows}
                            displayMode={cryptoV2.displayMode}
                            onChange={partialRefresh}
                            type="currency" key={"key101" + Math.random() + "-" + Math.random()} />
                    </span>
                    {cryptoV2.displayMode === DisplayModeEnum.VIEW && <Tooltip title="Gains"><span style={{ width: "20%", display: "inline-block" }}><CryptoGauge displayMode={cryptoV2.displayMode} key={"key811" + Math.random() + "-" + Math.random()}
                        reverse={true} perc={coeff} twoLines={true} /></span></Tooltip>}
                    {cryptoV2.displayMode === DisplayModeEnum.VIEW && <Tooltip title="Montant actuel du bag"><span style={{ width: "20%", display: "inline-block", textAlign: "right" }}>${cryptoExchangeV2.exchangeBagPrice && cryptoExchangeV2.exchangeBagPrice.toFixed(2)}</span></Tooltip>}
                    {cryptoV2.displayMode === DisplayModeEnum.VIEW && <Tooltip title="Pourcentage de l'objectif défini"><span style={{ width: "20%", display: "inline-block", textAlign: "right" }}>{cryptoExchangeV2.invested && totalAmount > 0 && <span>{'(' + toFixed(cryptoExchangeV2.invested * 100 / totalAmount, 0) + '%)'}</span>}</span></Tooltip>}
                    {cryptoV2.displayMode === DisplayModeEnum.VIEW && <Tooltip title="Total montant objectif défini"><span style={{ width: "20%", display: "inline-block", textAlign: "right" }}>${toFixed(totalAmount, 0)}</span></Tooltip>}
                </div>
                <br />
                <FieldEditorV2
                    tooltip="Commentaire"
                    object={cryptoExchangeV2} objectKey="comment"
                    displayMode={cryptoV2.displayMode}
                    rows={props.rows}
                    onChange={partialRefresh} type="comment" key={"key10" + Math.random() + "-" + Math.random()} />

            </TableCell>

            <TableCell key={"key9" + Math.random() + "-" + Math.random()}
                colSpan={3}
                className="crypto-row-targets"
                style={{ height: (cryptoV2.displayMode === DisplayModeEnum.VIEW ? "150px" : "200px") }}>
                <table style={{ height: "100%" }}>
                    <tbody>
                        <TableRow style={{ height: (cryptoV2.displayMode === DisplayModeEnum.VIEW ? "150px" : "200px"), width: "100%" }}>
                            <TableCell className="crypto-row-targets-table">
                                <CryptoRowTargetScrollerV2
                                    cryptoExchangeV2={cryptoExchangeV2}
                                    cryptoV2={cryptoV2}
                                    onRemoveTarget={onRemoveTarget}
                                    partialRefresh={partialRefresh}
                                    fullRefresh={props.fullRefresh}
                                    onAddTarget={props.onAddTarget} />
                            </TableCell>
                        </TableRow>
                    </tbody>
                </table>
            </TableCell>
        </TableRow>
    );
};



export default CryptoRowExchangeV2;
