import React from "react";
import "../../CryptoTable.css";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts";
import { Row, Col, Table } from "reactstrap";
import { TableBody } from "@mui/material";
import { calculBarChartDataExchange, pieColors } from "./CryptoGraphUtils";
import { porttcfolio } from "../../data/CryptoData";
import { ExchangeTotal } from "../data/CryptoDataTypeV2";

interface ChildProps {
}

let arcLabel: any = [];
let arcLabelTarget: any = [];
let arcLabelExchange: any = [];


const getArcLabel = (params: any) => {
    if (arcLabel != null && arcLabelTarget != null) {
        let TOTAL = 0;
        for (let item of arcLabel) {
            TOTAL = TOTAL + item.value;
        }
        return `${params.label2}`;
    } else {
        return '';
    }

};
const getArcLabelTarget = (params: any) => {
    if (arcLabel != null && arcLabelTarget != null) {
        let TOTAL = 0;
        for (let item of arcLabelTarget) {
            TOTAL = TOTAL + item.value;
        }
        return `${params.label2}`;
    } else {
        return '';
    }

};

const renderPie = (data: any, target: boolean) => {
    return <PieChart
        colors={pieColors}
        slotProps={{
            legend: {
                padding: 0,
            },
        }}
        sx={{
            [`& .${pieArcLabelClasses.root}`]: {
                fill: 'black',
                fontSize: '14px',
                fontWeight: 'bold'
            },
        }}
        series={[
            {
                outerRadius: 210,
                innerRadius: 30,
                paddingAngle: 1,
                cornerRadius: 5,
                cx: 500,
                data
            },
        ]}
        width={1200}
        height={500}
    />;
}

const CryptoPiePerExchangeV2: React.FC<ChildProps> = (props: ChildProps) => {

    arcLabelExchange = [];
    calculBarChartDataExchange(arcLabelExchange);

    let sortedItemsExchange: ExchangeTotal[] = porttcfolio.transient.exchanges;
  
    return (
        <span>
            <Table className="cryptoPie" id="cryptoPie">
                <TableBody>

                    <Row className="cryptoPieRow">
                        <Col md="12" className="cryptoPieItemExpected">
                            Portefeuille actuel par exchange: ${porttcfolio.transient.totalBeforeTax?.toFixed(0)} USDT
                        </Col>

                    </Row>
                    <Row className="cryptoPieRow">
                        <Col md="12" className="cryptoPieItem">
                            {renderPie(sortedItemsExchange, false)}
                        </Col>
                    </Row>
                </TableBody>
            </Table></span>);
};

export default CryptoPiePerExchangeV2;