import {
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import PasswordEditorV2 from '../v2/editor/PasswordEditorV2 ';
import { useState } from 'react';
import { porttcfolio, updateExchange } from '../data/CryptoData';
import { Exchange } from '../v2/data/CryptoDataTypeV2';
import { updatePortfolioInBrowserCache } from '../data/CryptoCache';
import { exchangesConfigArray } from '../data/CryptoConfig';

interface IProps {
}

export const initAdminExchanges = () => {

    if (porttcfolio.exchanges) {
    } else {
        porttcfolio.exchanges = [];
    }

    for (let j = 0; j < exchangesConfigArray.length; j++) {
        let myObj: Exchange = exchangesConfigArray[j];
        let trouve = false;
        for (let i = 0; i < porttcfolio.exchanges.length; i++) {
            if (myObj.editable && porttcfolio.exchanges[i].exchange.toUpperCase() === myObj.exchange.toUpperCase()) {
                trouve = true;
                break;
            }
        }
        if (myObj.editable && !trouve) {
            porttcfolio.exchanges.push(myObj);
        }
    }

    return porttcfolio.exchanges;
}

const AdminExchanges: React.FC<IProps> = () => {

   

    const [exchangesRows, setExchangesRows] = useState(initAdminExchanges());

    const handleChange = (index: any, field: any, value: any) => {
        if (exchangesRows !== undefined) {
            const updatedRows = exchangesRows.map((row: any, i: any) =>
                i === index ? { ...row, [field]: value } : row
            );
            setExchangesRows(updatedRows);

            for (let i = 0; i < exchangesRows.length; i++) {
                updateExchange(exchangesRows[i]);
            }
            updatePortfolioInBrowserCache();
        }
    };



    return (<TableContainer component={Paper}>
        <form id="adminForm">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Exchange</TableCell>
                        <TableCell>Clé</TableCell>
                        <TableCell>Secret</TableCell>
                        <TableCell>Passphrase</TableCell>
                        <TableCell>Exclusion</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {(exchangesRows !== undefined) && exchangesRows.map((row: any, index: any) => (
                        <TableRow key={index} style={{ display: (row.editable ? '' : 'none') }}>
                            <TableCell>
                                {row.exchange}
                            </TableCell>
                            <TableCell>
                                <PasswordEditorV2 value={row.key}
                                    label="Votre access key"
                                    onChange={(e: any) =>
                                        handleChange(index, 'key', e.target.value)
                                    } />
                            </TableCell>
                            <TableCell>
                                <PasswordEditorV2
                                    value={row.secret}
                                    label="Votre secret"
                                    onChange={(e: any) =>
                                        handleChange(index, 'secret', e.target.value)
                                    }
                                />
                            </TableCell>
                            <TableCell>
                                <PasswordEditorV2
                                    value={row.passphrase}
                                    label="Votre passphrase"
                                    onChange={(e: any) =>
                                        handleChange(index, 'passphrase', e.target.value)
                                    }
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    value={row.exclusions}
                                    onChange={(e) =>
                                        handleChange(index, 'exclusions', e.target.value)
                                    }
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </form>
    </TableContainer>);
}

export default AdminExchanges;