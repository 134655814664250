"use client";


import ReactSpeedometer from "react-d3-speedometer";
import { porttcfolio } from "../../data/CryptoData";


export default function CryptoFearAndGreed() {


  return (
    <ReactSpeedometer 
    value={porttcfolio.transient.fearAndGreed}
    segments={5}
    currentValueText="Fear and Greed index"
    minValue={0}
    maxValue={100}
    width={400}
  height={300}
    customSegmentLabels={[
      {
        text: "Extrême Peur",
        color: "#555",
      },{
        text:  "Peur",
        color: "#555",
      },{
        text: "Neutre",
        color: "#555",
      },{
        text: "Cupidité",
        color: "#555",
      },
      {
        text: "Extrême Cupidité",
        color: "#555",
      }
    ]
    }
    />
  );
}
