import { Box, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { AddCircle } from "@mui/icons-material";

function CryptoTableHeaderV2(props: any) {
  const {
    order,
    orderBy,
    onRequestSort,
  } = props

  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property)
  }

  return (<TableHead>
    <TableRow>
      <TableCell align="center" padding={'normal'} className="addRow percLeft">
        <Tooltip title="Ajouter ligne"><IconButton onClick={props.onCryptoAdd}>
          <AddCircle />
        </IconButton></Tooltip>
      </TableCell>
      <TableCell
        key={"symbol"}
        colSpan={2}
        align="center"
        padding={'normal'}
        className="percLeft"
        sortDirection={orderBy === "symbol" ? order : false}
      >
        <TableSortLabel
          direction={orderBy === "symbol" ? order : 'asc'}
          onClick={createSortHandler("symbol")}
        >
          Crypto-monnaie
          {orderBy === "symbol" ? (
            <Box component="span">
            </Box>
          ) : null}
        </TableSortLabel>
      </TableCell>
      <TableCell align="center" padding={'normal'} className="percLeft">Tendance</TableCell>
      <TableCell align="center" padding={'normal'} className="percLeft">Capital</TableCell>
    </TableRow>


  </TableHead>);
}


export default CryptoTableHeaderV2;