import { useRef, useState } from "react";
import "../../array/CryptoRowTargetScroller.css";
import { IconButton, TableBody, TableCell, TableRow, Tooltip } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { CryptoExchangeV2, CryptoV2, DisplayModeEnum, StatusEnum, TargetDataV2 } from "../data/CryptoDataTypeV2";
import CryptoRowTargetV2 from "./CryptoRowTargetV2";
import { isStableCoin } from "../util/PortfolioUtil";

interface IProps {
  cryptoV2: CryptoV2;
  cryptoExchangeV2: CryptoExchangeV2;
  onRemoveTarget: any;
  partialRefresh: any;
  fullRefresh: any;
  onAddTarget: any;
}

const CryptoRowTargetScrollerV2: React.FC<IProps> = ({ cryptoV2, cryptoExchangeV2, onRemoveTarget, partialRefresh, fullRefresh, onAddTarget }) => {

  let targets = [];
  if (cryptoExchangeV2) {

    if (isStableCoin(cryptoV2.symbol)) {
      cryptoExchangeV2.targets = [];
    }

    for (let i = 0; i < cryptoExchangeV2.targets.length; i++) {
      targets.push(<CryptoRowTargetV2 key={"key02" + Math.random() + "-" + Math.random()}
        cryptoV2={cryptoV2} targetPos={i} cryptoExchangeV2={cryptoExchangeV2} onAdd={onAddTarget}
        onRemoveTarget={onRemoveTarget}
        partialRefresh={partialRefresh} fullRefresh={fullRefresh}
      />);
    }

  }
  const elementRef = useRef(null);
  const [arrowDisable, setArrowDisable] = useState(true);

  const handleHorizantalScroll = (element: any, speed: any, distance: any, step: any) => {

    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      setArrowDisable(element.scrollLeft === 0);
    }, speed);
  };

  let isToAdd = false;
//  let bagPerc = usedBagPercV2(cryptoExchangeV2);
    if (isStableCoin(cryptoV2.symbol)) {
      targets.push(<TableCell key={"key01" + Math.random() + "-" + Math.random()} align="right" className="addTarget"></TableCell>);
    } else {
      isToAdd = true;
    }

  const onAddTarget2 = () => {
    let targetData: TargetDataV2 = {
      rate: 0,
      price: 0,
      bag: 0,
      bagPerc: 0,
      perc: null,
      status: StatusEnum.Init,
      graphProcessed: false
    }

    cryptoExchangeV2.targets.push(targetData);
    onAddTarget();
  }


  return (
    <div className={"rowTargets-container" + (targets.length > 4 ? " show" : " hide")}>
      <button
        className="rowTargets-container-inside ttcButton2"
        onClick={() => {
          handleHorizantalScroll(elementRef.current, 25, 100, -10);
        }}
        disabled={arrowDisable}
      >
        &lt;
      </button>
      <div className="rowTarget-container" ref={elementRef}>
        <table style={{ borderCollapse: "collapse" }}>
          <TableBody>
            <TableRow>
              {targets}
            </TableRow>
          </TableBody>
        </table>
      </div>
      <button
        className="rowTargets-container-inside ttcButton2"
        onClick={() => {
          handleHorizantalScroll(elementRef.current, 25, 100, 10);
        }}
      >
        &gt;
      </button>
      {
        isToAdd && cryptoV2.displayMode === DisplayModeEnum.EDIT && <Tooltip title="Ajouter objectif">
          <IconButton onClick={onAddTarget2} className="addTarget" >
            <AddCircle />
          </IconButton>
        </Tooltip>
      }
    </div>
  );
};
export default CryptoRowTargetScrollerV2;
