import { porttcfolio } from "./CryptoData";

//needed for json parse/jsonify - do not remove
Date.prototype.toJSON = function () {
    return this.getTime().toString();
}

export const updatePortfolioInBrowserCache = () => {
    let strPorttcfolio = JSON.stringify(porttcfolio);
    localStorage.setItem("cryptool", strPorttcfolio);
}