
export interface Porttcfolio {
    cryptosV2:CryptoV2[];
    investment: number;
    impotsPerc: number;
    exchanges: Exchange[];
    transient: PorttcfolioTransient;  
    currency?: CurrencyEnum;//v3
  }

export interface CryptoV2{
    ttcId: string,
    symbol: string,
    name?: string,
    coingeckoId?: string,
    image?: string,
    market_cap?: number,
    market_cap_rank?: number,
    ath?: number,
    unitPrice?: number,
    cryptoBagPrice: number,
    price_change_percentage_24h?: number,
    history?: string,
    comment?: string | null,
    exchanges:CryptoExchangeV2[];
    displayMode?: DisplayModeEnum;
}

export interface CryptoExchangeV2{
    ttcId: string,
    name:string,
    bag: number,
    exchangeBagPrice: number,
    automaticUpdate:boolean,    
    invested?: number,/*todo cst rename to be ore clear */
    updatedFromExchange:boolean,
    comment?: string | null,
    targets: TargetDataV2[]
}
export interface TargetDataV2 {
    rate: number,
    price?: number,
    bag?: number,
    bagPerc?: number,
    perc?: number | null,
    status: StatusEnum,
    graphProcessed?: boolean,
    reinvestedAmount?:number
  }

export interface CryptosV2{
    items: CryptoV2[]
}

export interface PorttcfolioTransient{
    exchanges:ExchangeTotal[];
    cryptos:CryptoTotal[];
    cryptoTargets:CryptoTargetTotal[];
    cryptoSummary:CryptoSummary[];
    totalTarget:number;
    totalInvested:number;
    totalReInvested:number;
    totalSold:number;
    totalSoldMinusReinvested:number;
    totalBeforeTax:number;
    totalTax:number;
    totalAfterTax:number;
    totalGains:number;
    allLoaded:boolean;
    rateEurUsd?:number;//v3
    rateEurUserCurrency?:number;//v3
    displayInUserCurrency?:boolean;//v3
    fearAndGreed?: number;
  }
  export interface ExchangeTotal{
    exchange:string;
    value: number;
    exchangeBagPrice: number;
    label:string;
    label2:string;
  }
  export interface CryptoSummary{
    symbol:string;
    totalLeft:number;
    sold:number;
    reinvested:number;
  }
  export interface CryptoTotal{
    symbol:string;
    value: number;
    label:string;
    label2:string;
  }
  export interface CryptoTargetTotal{
    symbol:string;
    id:string;
    value: number;
    label:string;
    label2:string;
  }
  export interface Exchange{
    exchange: string;
    key: string;
    secret: string;
    passphrase: string;
    exclusions:string;
    buyUrl?:string;
    editable:boolean;
  }
  
  export interface ExchangeDef{
    name: string;
    image?: string;
  }
  
  export interface TotalTargetData {
    label: string;
    totalTarget: number;
    impots: number;
    gain: number;
    totalSold: number;
  }

  export enum StatusEnum {
    Init = 0,
    Order = 1,
    Sold = 2
  }
  
  export enum CurrencyEnum {
    EUR = "EUR",
    USD = "USD",
    CAD = "CAD",
    CHF = "CHF"
  }

  export enum DisplayModeEnum{
    VIEW = "VIEW",
    EDIT = "EDIT"
  }